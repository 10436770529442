import React, { FC, useEffect } from 'react';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import { OG_PAGE_TYPES } from 'common/Seo/constants';
import Banner from 'components/Banner';
import { BannerMode } from 'components/Banner/models.d';
import PageDescription from 'components/PageDescription';
import SecondaryNavigation from 'components/SecondaryNavigation';
import { bodyAdapter } from 'utils/bodyAdapter';
import BodyRenderer from 'utils/bodyRenderer';
import createBreadcrumbs from 'utils/createBreadcrumbs';
import { gtmService } from 'utils/gtmService';

import { ProductDetailsPageProps } from './models';

const ProductDetailsPage: FC<ProductDetailsPageProps> = ({
  data: {
    productDetailsPage: { seo, pageDescription, body, banner, langProps, name, category },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const adaptedBody = bodyAdapter(body);
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const secondaryNavigationAnchors = adaptedBody
    .map((item) => item.value.secondaryNavigationAnchor)
    .filter((item) => item);

  const productImage =
    banner?.[0]?.image?.imagePicker?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;

  gtmService.enrichShoppingOptions(adaptedBody, {
    name,
    category,
    pathname,
  });

  useEffect(() => {
    const timeoutId = gtmService.emitProductView(adaptedBody);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Layout
      {...{
        seo: { ...seo, seoOGType: seo.seoOGType || OG_PAGE_TYPES.PRODUCT },
        langProps,
        breadcrumbs,
        pagePathname,
        additionalSchema: 'Product',
        schemaImageUrl: productImage,
      }}
    >
      {banner ? <Banner {...banner[0]} mode={BannerMode.product} /> : null}
      {secondaryNavigationAnchors ? (
        <SecondaryNavigation anchors={secondaryNavigationAnchors} lang={langProps.lang} />
      ) : null}
      {pageDescription ? <PageDescription {...pageDescription} /> : null}
      {adaptedBody.map((component) => {
        const [key] = useId();

        return <BodyRenderer {...component} key={key} />;
      })}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    productDetailsPage(link: { eq: $link }) {
      name
      category
      seo {
        ...SEOStructureFragment
      }
      pageDescription {
        description
        backgroundColor
        isCustomStyleDescription
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      banner {
        ...BannerFragment
      }
      body {
        textWithImage {
          ...TextWithImageFragment
        }
        productsListing {
          ...ListingFragment
        }
        faq {
          ...FaqFragment
        }
        articlePromoListing {
          ...ListingFragment
        }
        textbox {
          ...TextboxFragment
        }
        teaser {
          ...TeaserFragment
        }
        shoppingOptions {
          ...ShoppingOptionsFragment
        }
        ingredients {
          ...IngredientsFragment
        }
        textWithVideo {
          ...TextWithVideoFragment
        }
        newsletterNodePicker {
          ...NewsletterBannerFragment
        }
        howToUse {
          ...HowToUseFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ProductDetailsPage;
